<template>
    <div class="RoleManagementBox item-b"  v-loading="showLoad" element-loading-text="拼命加载中">
        <FormItem :formList="formList" :btn="btn" @search="onSearch" @add="onAdd" @reset="onReset" :btnObj="btnObj" :search="search"/>
        <Table ref="table" :tableList="tableList" :tableData="tableData" @operation="onHandle" @currentNum="current" :total="total"/>
        <el-drawer custom-class="drawer-class" :visible.sync="show" :show-close="false" :withHeader="false" :wrapperClosable="false">
            <div class="drawer-box flex-col">
                <div class="title">{{isEdit ? '编辑角色' : '新增角色'}}</div>
                <div class="table auto">
                    <el-form
                            :model="postFormData"
                            size="small"
                            :rules="postFormDataRules"
                            label-position="left"
                            label-width="auto"
                            ref="addForm"
                            :hide-required-asterisk="true"
                    >
                        <el-form-item label="角色名称" prop="nickName">
                            <el-input placeholder="请输入角色名称" v-model="postFormData.nickName" class="custom-submit-input"></el-input>
                        </el-form-item>
                        <el-form-item label="角色描述" prop="description">
                            <el-input placeholder="请输入角色描述" type="textarea" :rows="6" v-model="postFormData.description" class="custom-submit-textarea"></el-input>
                        </el-form-item>
                        <el-form-item label="控制权限" prop="menuId">
                            <el-checkbox-group v-model="menuId" @change="handleCheckedMenuIdChange" style="display: flex;flex-direction: column;">
                                <el-checkbox v-for="item in menuItem" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button type="primary submit-primary" v-if="!isEdit" @click="saveAdd">保存</el-button>
                    <el-button type="primary submit-primary" v-else @click="update">保存</el-button>
                    <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import axios from '@/request'
export default {
  name: "RoleManagement",
  data(){
    return{
      showLoad:false,
      formList:[
        {
          props:'nickName',
          el:'el-input',
          label:'角色名',
          placeholder:'请输入角色名称'
        },
      ],
      btnObj:{
        search:'查询',
        add:'新增',
        reset:'重置'
      },
      btn:'search,add,reset',
      tableList:[
        {
          prop:'index',
          label:'序号',
          width:''
        },
        {
          prop:'nickName',
          label:'角色名称',
          width:''
        },
        {
          prop:'description',
          label:'角色描述',
          width:''
        },
        {
          prop:'op',
          label:'操作',
          btn:[
            {
              type:'primary',
              name:'编辑',
              prop:'edit',
            },
            {
              type:'danger',
              name:'删除',
              prop:'del',
            }
          ]
        }
      ],
      tableData:[],
      page:1,
      size:10,
      total:0,
      search: {
        nickName:''
      },
      show: false,
      menuId:[],
      menuItem:[{id: 1,name: "首页"}],
      postFormData:{},
      postFormDataRules:{
        nickName:[
          { required: true, message: "请输入角色名称" },
          { max: 20, message: "最多20个字符" },
        ],
        description:[
          { required: true, message: "请输入角色描述" },
        ],
        phone: [
          { required: true, message: "请输入联系电话" },
          {  tyep: "regexp", pattern: /^1[0-9]{10}$/, message: "请输入正确的联系电话" }
        ],
      },
      isEdit:false
    }
  },
  mounted() {
    this.getData(this.page,this.size,this.search)
  },
  methods:{
    // 菜单
    menu(){
        axios.get('/menu/findTree').then(res=>{
          this.menuItem = res.length != 0 ? res.map(item => {return {id:item.id,name:item.displayName}}) : []
      
        })
    },
    getData(page,size,val){
      this.showLoad=true
      axios.get(`/role/page/${page}/${size}`,{
        params:{
          ...val
        }
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
        this.showLoad=false
      })
    },
    onSearch(val){
      this.search = val
      this.getData(this.page,this.size,val)
    },
    onAdd(val){
      this.menu()
      this.show = true
      this.isEdit = false
      this.menuId = []
      this.postFormData = Object.assign({},{},)
    },
    onReset(val){
      this.$refs.table.lastEmittedPage()
      this.getData(1,10,val)
    },
    // 表格操作按钮
    onHandle(data){
      // opVal ：执行表格编辑删除等操作
      if (data.opVal == 'del'){
        this.del(data.data)
      }else if (data.opVal == 'edit'){
        this.edit(data.data)
      }
    },
    // 分页事件
    current(val){
      this.page = val
      this.getData(this.page,this.size,{})
    },
    handleCheckedMenuIdChange(value){
      this.menuId = value
    },
    cancelAdd() {
      this.$refs.addForm.resetFields();
      this.show = false;
    },
    edit(data){
      this.menu()
      this.isEdit = true
      this.show = true
      this.menuId = data.menuId
      this.postFormData = Object.assign({},data)
    },
    saveAdd() {
      this.$refs.addForm.validate(valid => {
        if(valid){
          let {description,nickName} = this.postFormData
          let dataObj = {
            description,nickName,menuId:this.menuId
          }
          axios.post('/role/add',{
            ...dataObj
          }).then(res=>{
            if (res == null){
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.cancelAdd()
              this.getData(this.page,this.size,{})
            }else{
              this.$message({
                message: '新增失败',
                type: 'error'
              });
            }
          })
        }
      });
    },
    update(){
      this.$refs.addForm.validate(valid => {
        if(valid){
          let {id,description,nickName} = this.postFormData
          let dataObj = {
            id,description,nickName,menuId:this.menuId
          }
          axios.post('/role/update',{
            ...dataObj
          }).then(res=>{
            if (res == null){
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.cancelAdd()
              this.getData(this.page,this.size,this.search)
            }else{
              this.$message({
                message: '修改失败',
                type: 'error'
              });
            }
          })
        }
      });
    },
    del(data){
      this.$confirm('此操作将永久删除角色信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post(`/role/deleteById/${data.id}`).then(res=>{
          if (res == null){
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getData(this.page,this.size,this.search)
          }else{
            this.$message({
              message: '删除失败',
              type: 'error'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.RoleManagementBox{
    width: 100%;
    .input{
        width: 100%;
    }
    .drawer-box {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;

        .title {
            padding: 20px;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            border-bottom: solid 1px #dadada;
        }
        .table {
            padding: 20px;
            overflow: auto;
        }
        .btn {
            padding: 30px 0;
        }
    }
}
</style>
